import {controller} from '@github/catalyst'
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import {createBrowserHistory} from './create-browser-history'
import type {EmbeddedData} from './embedded-data-types'
import {NavigatorClientEntry} from './NavigatorClientEntry'
import {
  getReactDataRouterApp,
  getReactNavigatorApp,
  type DataRouterAppRegistrationFn,
  type NavigatorAppRegistrationFn,
} from './react-app-registry'
import {ReactBaseElement} from './ReactBaseElement'
import {routesWithProviders} from './future/RoutesWithProviders'
import {v7_routeProviderFutureFlags, v7_routerFutureFlags} from './react-router-future-flags'

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'react-app': React.DetailedHTMLProps<React.HTMLAttributes<ReactAppElement>, ReactAppElement>
    }
  }
}

// What is this silliness? Is it react or a web component?!
// It's a web component we use to bootstrap react apps within the monolith.
@controller
export class ReactAppElement extends ReactBaseElement<EmbeddedData> {
  nameAttribute = 'app-name'

  async getReactNode(embeddedData: EmbeddedData, onError: (error: Error) => void): Promise<JSX.Element> {
    // The component that wraps this React app will know if the app should be rendered with
    // date router (instead of navigator router) and it does this by setting
    // a `data-data-router-enabled="true"` attribute on this `<react-app>` element.
    // Remember we might have two apps called `some-cool-app`, because it started as a navigator app
    // (i.e. jsonRoute) and now also exists a data router app (i.e. queryRoute). At runtime, we might
    // toggle between which to use based on feature flags.
    const isDataRouterEnabled = this.getAttribute('data-data-router-enabled') === 'true'

    if (isDataRouterEnabled) {
      const app = await getReactDataRouterApp(this.name)
      return this.#getDataRouterNode(embeddedData, onError, app.registration)
    }

    const app = await getReactNavigatorApp(this.name)
    return this.#getNavigatorNode(embeddedData, onError, app.registration)
  }

  async #getDataRouterNode(
    embeddedData: EmbeddedData,
    onError: (error: Error) => void,
    registration: DataRouterAppRegistrationFn,
  ) {
    const {routes} = registration()
    const router = createBrowserRouter(
      routesWithProviders(routes, {ssrError: this.ssrError, appName: this.name, wasServerRendered: this.hasSSRContent}),
      {future: v7_routerFutureFlags},
    )
    return <RouterProvider router={router} future={v7_routeProviderFutureFlags} />
  }

  async #getNavigatorNode(
    embeddedData: EmbeddedData,
    onError: (error: Error) => void,
    registration: NavigatorAppRegistrationFn,
  ) {
    const {App, routes} = registration()
    const initialPath = this.getAttribute('initial-path') as string

    if (this.isLazy) {
      const request = await fetch(initialPath, {
        mode: 'no-cors',
        cache: 'no-cache',
        credentials: 'include',
      })
      const {payload} = await request.json()

      embeddedData.payload = payload
    }

    const window = globalThis.window as Window | undefined

    // Initial path is set by ruby. Anchors are not sent to the server.
    // Therefore anchors must be set explicitly by the client.
    const {pathname, search, hash} = new URL(
      `${initialPath}${window?.location.hash ?? ''}`,
      window?.location.href ?? 'http://workers-playground-icy-pine-ac0b.fatiao.workers.dev/proxy/https://github.com',
    )

    const history = createBrowserHistory({window})
    const {key, state} = history.location
    const initialLocation = {
      pathname,
      search,
      hash,
      key,
      state,
    }

    return (
      <NavigatorClientEntry
        appName={this.name}
        initialLocation={initialLocation}
        history={history}
        embeddedData={embeddedData}
        routes={routes}
        App={App}
        wasServerRendered={this.hasSSRContent}
        ssrError={this.ssrError}
        onError={onError}
      />
    )
  }

  get isLazy() {
    return this.getAttribute('data-lazy') === 'true'
  }
}
